import React from "react";
import Navbar from "../components/Navbar";
import landing from "../assets/image/SWN_Image.jpg";
import bgVideo from "../assets/video/SWN_bg.mov";
import logo from "../assets/image/logo.jpg";

const HomePage = () => {
  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center pt-4">
        <img
          src={logo}
          alt="..."
          className="max-w-full w-3/5 h-auto align-middle"
        />
        <div className="flex justify-around px-10 py-10 bg-primary w-full flex-col md:flex-row">
          <div className="text-white mb-4">
            <p className="font-bold">FORT WORTH, TX OFFICE</p>
            <p>2833 Crockett St. #138</p>
            <p>Fort Worth, Texas 76107</p>
            <p>1-844-930-BOND</p>
          </div>
          <div className="text-white mb-4">
            <p className="font-bold">MAILING ADDRESS</p>
            <p>1141 N. Loop 1604 E. #105 - 429</p>
            <p>San Antonio, Texas 78232</p>
          </div>
          <div className="text-white mb-4">
            <p className="font-bold">SAN ANTONIO, TX OFFICE</p>
            <p>8940 Fourwinds Dr. #135</p>
            <p>Windcrest, Texas 78239</p>
            <p>1-844-930-BOND</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
