import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { database } from "../providers/firebase";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const defaultColDef = {
  resizable: true,
  editable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true,
  valueSetter: (params) => {
    params.data[params.colDef.field] = params.newValue;
    return params.newValue !== params.oldValue;
  },
  valueGetter: (params) => params.data[params.colDef.field],
};

const columnTypes = {
  nonEditableColumn: { editable: false },
};

const rowHeight = 48;

const OffersPage = () => {
  const [offers, setOffers] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      let tempOffers = [];
      let updatedOffers = [];
      await database.ref("OT").once("value", (snapshot) => {
        if (!!snapshot.val()) {
          snapshot.forEach((child) => {
            tempOffers.push(child.val());
          });
        }
      });

      let item = {};
      console.log("tempOffers", tempOffers);
      for (item of tempOffers) {
        const gUid = item.UserUid;
        const sUid = item.SCUid;
        const bidId = item.BidId;
        const snapshot1 = await database.ref(`GCT/${gUid}`).once("value");
        const snapshot2 = await database.ref(`SCT/${sUid}`).once("value");
        const snapshot3 = await database.ref(`BT/${bidId}`).once("value");

        if (snapshot1.val() && snapshot2.val() && snapshot3.val()) {
          const gEmail = snapshot1.val().Email;
          const gLegal = snapshot1.val().LegalEntity;
          const sEmail = snapshot2.val().Email;
          const sLegal = snapshot2.val().LegalEntityName;
          const budget = snapshot3.val().Budget;
          const proposal = snapshot3.val().Proposal;
          const timeline = snapshot3.val().Timeline;
          const newItem = {
            ...item,
            gEmail,
            gLegal,
            sEmail,
            sLegal,
            budget,
            proposal,
            timeline,
          };
          updatedOffers.push(newItem);
        }
      }
      setOffers(updatedOffers);
      console.log("offers", offers);
    }

    fetchData();
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onCellValueChanged = (event) => {
    console.log("Data after change is", event.data);
    const offerRef = database.ref(`OT/${event.data.OfferId}`);
    offerRef.update(event.data);
  };

  const onSelectionChanged = () => {
    var selectedRows = gridApi.getSelectedRows();
    setSelectedRows(selectedRows);
  };

  const onPressDelete = () => {
    gridApi.applyTransaction({ remove: selectedRows });

    selectedRows.forEach((row) => {
      const key = row.OfferId;
      database.ref(`OT/${key}`).remove();
    });
    setIsOpen(false);
  };

  const onDeleteOffers = () => {
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  // const onPressAdd = (e) => {
  //   e.preventDefault();
  //   var newItems = [[]];
  //   var res = gridApi.applyTransaction({
  //     add: newItems,
  //   });
  // }

  return (
    <div className="w-full">
      <Navbar />
      <p className="text-center font-bold mb-4">Offers</p>
      <div className="ag-theme-alpine w-full h-96">
        <AgGridReact
          rowData={offers}
          rowHeight={rowHeight}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          rowSelection={"multiple"}
          rowMultiSelectWithClick={true}
          onCellValueChanged={onCellValueChanged}
          onSelectionChanged={onSelectionChanged}
          onGridReady={onGridReady}
        >
          <AgGridColumn
            field="OfferId"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
            checkboxSelection={true}
          ></AgGridColumn>
          <AgGridColumn
            field="gEmail"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
          ></AgGridColumn>
          <AgGridColumn
            field="gLegal"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
          ></AgGridColumn>
          <AgGridColumn
            field="sEmail"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
          ></AgGridColumn>
          <AgGridColumn
            field="sLegal"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
          ></AgGridColumn>
          <AgGridColumn
            field="budget"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
          ></AgGridColumn>
          <AgGridColumn
            field="proposal"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
          ></AgGridColumn>
          <AgGridColumn
            field="timeline"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
          ></AgGridColumn>
          <AgGridColumn
            field="Description"
            sortable={true}
            filter={true}
          ></AgGridColumn>
          <AgGridColumn field="isAccepted"></AgGridColumn>
        </AgGridReact>
      </div>
      <div className="flex items-center justify-evenly">
        <button
          className={`w-48 ${
            selectedRows.length === 0 ? "bg-gray-500" : "bg-primary"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={onDeleteOffers}
          disabled={selectedRows.length === 0}
        >
          Delete Selected Offers
        </button>
      </div>
      <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
        <p className="text-lg">
          Do you really want to delete the selected offers?
        </p>
        <div className="flex justify-center">
          <button
            className={`w-24 bg-primary text-white py-1 my-3 mr-3 rounded-full focus:outline-none`}
            onClick={onPressDelete}
          >
            OK
          </button>
          <button
            className={`w-24 bg-primary text-white py-1 my-3 ml-3 rounded-full focus:outline-none`}
            onClick={onCloseModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default OffersPage;
