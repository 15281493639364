import React, { useState } from "react";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { isMobile } from "react-device-detect";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const ContactUsPage = () => {
  const [modalIsOpen1, setIsOpen1] = useState(false);
  const [modalIsOpen2, setIsOpen2] = useState(false);

  const onCloseModal1 = () => {
    setIsOpen1(false);
  };

  const onCloseModal2 = () => {
    setIsOpen2(false);
  };

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center py-4">
        <p className="mt-3 text-xl px-4 text-center">
          Email us to &nbsp;
          <a
            className="text-primary underline"
            href="mailto:richard@bailbondbidsnow.com"
          >
            richard@bailbondbidsnow.com
          </a>
          &nbsp;or call &nbsp;
          <a className="text-primary underline" href="tel:1-844-930-2663">
            1-844-930-BOND (1-844-930-2663)
          </a>
          &nbsp;with any questions or inqueries.
        </p>
        <Link
          to="#"
          onClick={(e) => {
            window.location = "mailto:richard@bailbondbidsnow.com";
            e.preventDefault();
          }}
          className="bg-primary hover:opacity-80 w-40 py-2 text-white text-center mt-20 rounded-3xl"
        >
          Send Email
        </Link>
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            if (isMobile || window.navigator.appVersion.indexOf('Mac') !== -1) 
              window.location = 'tel:1-844-930-2663';
            else
              setIsOpen1(true);
          }}
          className="bg-primary hover:opacity-80 w-40 py-2 text-white text-center mt-4 rounded-3xl"
        >
          Phone Call
        </Link>
      </div>
      <Modal isOpen={modalIsOpen1} style={customStyles} ariaHideApp={false}>
        <p className="text-lg">Please call 1-844-930-BOND.</p>
        <div className="flex justify-center">
          <button
            className={`w-24 bg-primary text-white py-1 my-3 mr-3 rounded-full focus:outline-none`}
            onClick={onCloseModal1}
          >
            OK
          </button>
        </div>
      </Modal>
      <Modal isOpen={modalIsOpen2} style={customStyles} ariaHideApp={false}>
        <p className="text-lg">
          Please use the Android and/or Apple Apps to direct message BailBondBidsNow.
        </p>
        <div className="flex justify-center">
          <button
            className={`w-24 bg-primary text-white py-1 my-3 mr-3 rounded-full focus:outline-none`}
            onClick={onCloseModal2}
          >
            OK
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ContactUsPage;
