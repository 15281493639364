import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA-FE8qxXi9edHmF2-0ZXGJTP2zCD3t44k",
  authDomain: "bailbondbidsnow-9ec7c.firebaseapp.com",
  databaseURL: "https://bailbondbidsnow-9ec7c-default-rtdb.firebaseio.com/",
  projectId: "bailbondbidsnow-9ec7c",
  storageBucket: "bailbondbidsnow-9ec7c.appspot.com",
  messagingSenderId: "283840668687",
  appId: "1:283840668687:web:d26b5fa15afe0f44cd2f7a",
  measurementId: "G-28ZGS5JVZE"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const database = firebase.database();
export const storage = firebase.storage();
