import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { auth, database } from "../providers/firebase";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignUp = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [error, setError] = useState(null);

  const createUserWithEmailAndPasswordHandler = async (
    event,
    email,
    password,
    confirmPwd
  ) => {
    event.preventDefault();

    if (password !== confirmPwd) {
      setError("Passwords don't match.");
      return;
    }

    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      setError(null);

      const aUserRef = database.ref(`AT/${user.uid}`);
      await aUserRef.set({
        Email: email,
        Approved: false,
        UserUid: user.uid,
      });

      toast("Please wait until the Super Admin approves you.");
      const msg = {
        personalizations: [
          {
            from: {
              email: "rey@bailbondbidsnow.com",
              name: "SubWorkNow",
            },
            to: [
              {
                email: email,
                name: "",
              },
            ],
          },
        ],
        from: {
          email: "rey@bailbondbidsnow.com",
          name: "SubWorkNow",
        },
        reply_to: {
          email: "rey@bailbondbidsnow.com",
          name: "SubWorkNow",
        },
        subject: "Congratulations",
        content: [
          {
            type: "text/html",
            value: `<!doctype html>
              <html>
                <body>
                  <div style="display: flex; align-items: center;">
                    <img src="https://i.ibb.co/b5WpDdv/logo.png" alt="logo" border="0">
                    <p style="font-weight: bold; margin-left: 8px; color: black;">SubWorkNow</p>
                  </div>
                  <p>Welcome to SubWorkNow.com.</p>
                  <p>This email confirms your Registration. If you did not register or have any questions feel free to contact us at 1-844-930-BOND (1-844-930-2663) or rey@bailbondbidsnow.com</p>
                </body>
              </html>
            `,
          },
        ],
      };

      axios
        .post(
          "https://us-central1-subworknow.cloudfunctions.net/sendEmail",
          msg,
          { headers: { "Content-Type": "application/json" } }
        )
        .then(function (response) {
          setTimeout(() => history.push("/"), 3000);
          console.log(response);
        })
        .catch(function (error) {
          setTimeout(() => history.push("/"), 3000);
          console.log(error);
        });
    } catch (error) {
      setError("Error Signing up with email and password");
    }

    setEmail("");
    setPassword("");
    setConfirmPwd("");
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    } else if (name === "confirmPwd") {
      setConfirmPwd(value);
    }
  };

  return (
    <div className="mt-8">
      <h1 className="text-3xl mb-2 text-center font-bold">Sign Up</h1>
      <div className="border border-blue-400 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
        {error !== null && (
          <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
            {error}
          </div>
        )}
        <form className="">
          <label htmlFor="userEmail" className="block">
            Email:
          </label>
          <input
            type="email"
            className="my-1 p-1 w-full border border-black-400"
            name="userEmail"
            value={email}
            placeholder="Your Email"
            id="userEmail"
            onChange={(event) => onChangeHandler(event)}
          />
          <label htmlFor="userPassword" className="block">
            Password:
          </label>
          <input
            type="password"
            className="mt-1 p-1 w-full border border-black-400"
            name="userPassword"
            value={password}
            placeholder="Your Password"
            id="userPassword"
            onChange={(event) => onChangeHandler(event)}
          />
          <label htmlFor="confirmPassword" className="block">
            Confirm Password:
          </label>
          <input
            type="password"
            className="mt-1 p-1 w-full border border-black-400"
            name="confirmPwd"
            value={confirmPwd}
            placeholder="Confirm Password"
            id="confirmPwd"
            onChange={(event) => onChangeHandler(event)}
          />
          <button
            className="bg-primary w-full py-2 text-white mt-4"
            onClick={(event) => {
              createUserWithEmailAndPasswordHandler(
                event,
                email,
                password,
                confirmPwd
              );
            }}
          >
            Sign up
          </button>
        </form>
        <p className="text-center my-3">
          Already have an account?{" "}
          <Link to="/" className="text-primary">
            Sign in here
          </Link>
        </p>
      </div>
      <ToastContainer />
    </div>
  );
};
export default SignUp;
