import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { database } from "../providers/firebase";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const defaultColDef = {
  resizable: true,
  editable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true,
  valueSetter: (params) => {
    params.data[params.colDef.field] = params.newValue;
    return params.newValue !== params.oldValue;
  },
  valueGetter: (params) => params.data[params.colDef.field],
};

const columnTypes = {
  nonEditableColumn: { editable: false },
};

const rowHeight = 42;

const GCUsersPage = () => {
  const [users, setUsers] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      let gcUsers = [];
      const snapshot = await database.ref(`FFSRT`).once("value");
      if (!!snapshot.val()) {
        snapshot.forEach((child) => {
          gcUsers.push(child.val());
        });
      }

      gcUsers.sort((a,b) => (a.JoinedAt < b.JoinedAt) ? 1 : ((b.JoinedAt < a.JoinedAt) ? -1 : 0));
      setUsers(gcUsers);
    }

    fetchData();
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onCellValueChanged = (event) => {
    const gcUserRef = database.ref(`FFSRT/${event.data.UserUid}`);
    gcUserRef.update(event.data);
  };

  const onSelectionChanged = () => {
    var selectedRows = gridApi.getSelectedRows();
    setSelectedRows(selectedRows);
  };

  const onPressDelete = async () => {
    gridApi.applyTransaction({ remove: selectedRows });

    let jobs = [];
    const snapshot2 = await database.ref(`JT`).once("value");
    if (!!snapshot2.val()) {
      snapshot2.forEach((child) => {
        jobs.push(child.val());
      });
    }

    let bids = [];
    const snapshot = await database.ref(`BT`).once("value");
    if (!!snapshot.val()) {
      snapshot.forEach((child) => {
        bids.push(child.val());
      });
    }
  
    selectedRows.forEach((row) => {
      const key = row.UserUid;
      database.ref(`FFSRT/${key}`).remove();

      const filterJobs = jobs.filter(job => job.UserUid === key);
      if (filterJobs && filterJobs.length > 0) {
        filterJobs.forEach(job => {
          database.ref(`JT/${job.JobId}`).remove();

          const filterBids = bids.filter(bid => bid.JobId === job.JobId);
          if (filterBids && filterBids.length > 0) {
            filterBids.forEach(bid => {
              database.ref(`BT/${bid.BidId}`).remove();
            })
          }
        });
      }
    });
    setIsOpen(false);
  };

  const onDeleteUsers = () => {
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  // const onPressAdd = (e) => {
  //   e.preventDefault();
  //   var newItems = [[]];
  //   var res = gridApi.applyTransaction({
  //     add: newItems,
  //   });
  // }

  return (
    <div className="w-full">
      <Navbar />
      <p className="text-center font-bold mb-4">Friends / Family</p>
      <div className="ag-theme-alpine w-full h-96">
        <AgGridReact
          rowData={users}
          rowHeight={rowHeight}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          rowSelection={"multiple"}
          rowMultiSelectWithClick={true}
          onCellValueChanged={onCellValueChanged}
          onSelectionChanged={onSelectionChanged}
          onGridReady={onGridReady}
          pivotPanelShow={"always"}
          paginationAutoPageSize={true}
          pagination={true}
        >
          <AgGridColumn
            field="UserUid"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
            checkboxSelection={true}
          ></AgGridColumn>
          <AgGridColumn
            field="Email"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
          ></AgGridColumn>
          <AgGridColumn
            field="Email"
            sortable={true}
            filter={true}
            headerName="Friend/Family Email"
            valueGetter={(params) => params.data.FFSR?.Email}
            valueSetter={(params) => {
              if (!params.data.FFSR)
                params.data.FFSR = {};
              params.data.FFSR.Email = params.newValue;
              return params.newValue !== params.oldValue;
            }}
          ></AgGridColumn>
          <AgGridColumn
            field="Name"
            sortable={true}
            filter={true}
            headerName="Friend/Family Name"
            valueGetter={(params) => params.data.FFSR?.Name}
            valueSetter={(params) => {
              if (!params.data.FFSR)
                params.data.FFSR = {};
              params.data.FFSR.Name = params.newValue;
              return params.newValue !== params.oldValue;
            }}
          ></AgGridColumn>
          <AgGridColumn
            field="Phone"
            sortable={true}
            filter={true}
            headerName="Friend/Family Phone"
            valueGetter={(params) => params.data.FFSR?.Phone}
            valueSetter={(params) => {
              if (!params.data.FFSR)
                params.data.FFSR = {};
              params.data.FFSR.Phone = params.newValue;
              return params.newValue !== params.oldValue;
            }}
          ></AgGridColumn>
          <AgGridColumn
            field="ArresteeName"
            sortable={true}
            filter={true}
            headerName="Arrestee's Name"
            valueGetter={(params) => params.data.Arrestee?.Name}
            valueSetter={(params) => {
              if (!params.data.Arrestee)
                params.data.Arrestee = {};
              params.data.Arrestee.Name = params.newValue;
              return params.newValue !== params.oldValue;
            }}
          ></AgGridColumn>
          <AgGridColumn
            field="Birthday"
            sortable={true}
            filter={true}
            headerName="Arrestee's Date of Birth"
            valueGetter={(params) => params.data.Arrestee?.Birthday}
            valueSetter={(params) => {
              if (!params.data.Arrestee)
                params.data.Arrestee = {};
              params.data.Arrestee.Birthday = params.newValue;
              return params.newValue !== params.oldValue;
            }}
          ></AgGridColumn>
          <AgGridColumn
            field="Facility"
            sortable={true}
            filter={true}
            width={300}
            headerName="Arrestee's Current Jail/Detention Facility"
            valueGetter={(params) => params.data.Arrestee?.Facility}
            valueSetter={(params) => {
              if (!params.data.Arrestee)
                params.data.Arrestee = {};
              params.data.Arrestee.Facility = params.newValue;
              return params.newValue !== params.oldValue;
            }}
          ></AgGridColumn>
          <AgGridColumn
            field="JoinedAt"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
          ></AgGridColumn>
        </AgGridReact>
      </div>
      <div className="flex items-center justify-evenly">
        <button
          className={`w-48 ${
            selectedRows.length === 0 ? "bg-gray-500" : "bg-primary"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={onDeleteUsers}
          disabled={selectedRows.length === 0}
        >
          Delete Selected Users
        </button>
      </div>
      <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
        <p className="text-lg">
          Do you really want to delete the selected users?
        </p>
        <div className="flex justify-center">
          <button
            className={`w-24 bg-primary text-white py-1 my-3 mr-3 rounded-full focus:outline-none`}
            onClick={onPressDelete}
          >
            OK
          </button>
          <button
            className={`w-24 bg-primary text-white py-1 my-3 ml-3 rounded-full focus:outline-none`}
            onClick={onCloseModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default GCUsersPage;
