import React from "react";
import Navbar from "../components/Navbar";

const AssistantPage = () => {
  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col py-4 px-20">
        <p className="text-lg font-bold underline">How It Works</p>
        <p className="mt-3">
          BailBondBidsNow.com is a platform designed to assist an Arrestee’s
          Friends and Family to find the best available terms from among
          multiple Bonding Companies.
        </p>
        <p className="mt-3">The process is very simple.</p>
        <p className="mt-3">
          An Arrestee’s Family/Friend seeking their release simply completes the
          “Arrestee’s Profile” section and confirms their request to receive
          “Bids for Bail”. Different Bonding Companies will view the “Arrestee’s
          Profile” information and begin making “Bids for Bail”. The Arrestee’s
          Family/Friend will then be notified by email and/or phone
          notifications as incoming “Bids for Bail” appear under the “Incoming
          Bids for Bail” section. The Family/Friend can then select or reach out
          to the Bonding Company for further information. Please note that
          BailBondBidsNow.com does not provide any specific contracting services
          between parties.
        </p>
        <p className="mt-3">
          This process will provide efficiency and time savings for both parties
          while ensuring that the Arrestee’s Family/Friend obtains the best
          bonding terms possible in the fastest manner to obtain release.
        </p>
        <p className="mt-3">
          Any questions or concerns may be made to&nbsp;
          <a
            className="text-blue-500 underline"
            href="mailto:richard@bailbondbidsnow.com"
          >
            richard@bailbondbidsnow.com
          </a>
          &nbsp;or by calling&nbsp;
          <a className="text-blue-500 underline" href="tel:1-844-930-BOND">
            1-844-930-BOND
          </a>
          . We are available and happy to assist.
        </p>
      </div>
    </div>
  );
};

export default AssistantPage;
