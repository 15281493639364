import React, { useEffect, useState } from "react";
import { auth, database } from "../providers/firebase";
import { ThreeBounce } from "better-react-spinkit";
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        setTimeout(() => {
          const aUserRef = database.ref(`AT/${user.uid}`);
          aUserRef.get().then(async (snapshot2) => {
            if (snapshot2.exists()) {
              await aUserRef.update({
                UserUid: user.uid,
              });

              if (
                snapshot2.val().Approved ||
                user.email === "rey@bailbondbidsnow.com"
              ) {
                setCurrentUser({ ...snapshot2.val(), UserUid: user.uid });
              } else {
                setCurrentUser(null);
              }
              setPending(false);
            } else {
              setCurrentUser(null);
              setPending(false);
            }
          });
        }, 500);
      } else {
        setCurrentUser(null);
        setPending(false);
      }
    });
  }, []);

  if (pending) {
    return (
      <div className="flex justify-center items-center flex-col h-screen">
        <ThreeBounce size={24} color="#03a9f4" />
        Please wait...
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
