import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { database } from "../providers/firebase";
import Modal from "react-modal";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const defaultColDef = {
  resizable: true,
  editable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true,
  valueSetter: (params) => {
    params.data[params.colDef.field] = params.newValue;
    return params.newValue !== params.oldValue;
  },
  valueGetter: (params) => params.data[params.colDef.field],
};

const columnTypes = {
  nonEditableColumn: { editable: false },
};

const rowHeight = 42;

const SCUsersPage = () => {
  const [users, setUsers] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [newUid, setNewUid] = useState("");

  useEffect(() => {
    async function fetchData() {
      let scUsers = [];
      const snapshot = await database.ref(`BCT`).once("value");
      if (!!snapshot.val()) {
        snapshot.forEach((child) => {
          scUsers.push(child.val());
        });
      }

      scUsers.sort((a, b) =>
        a.JoinedAt < b.JoinedAt ? 1 : b.JoinedAt < a.JoinedAt ? -1 : 0
      );
      setUsers(scUsers);
    }

    fetchData();
  }, [newUid]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onCellValueChanged = (event) => {
    if (event.data.UserUid) {
      console.log("Data after change is", event.data);
      const scUserRef = database.ref(`BCT/${event.data.UserUid}`);
      scUserRef.update(event.data);
    } else if (event.data.Email) {
      axios
        .post(
          "https://us-central1-bailbondbidsnow-9ec7c.cloudfunctions.net/addUser",
          { email: event.data.Email, password: "123456" },
          { headers: { "Content-Type": "application/json" } }
        )
        .then(async function (response) {
          console.log("response", response);
          if (response.data.error) {
            toast(response.data.error.message);
            return;
          }
          const scUserRef = database.ref(`BCT/${response.data.data.uid}`);
          await scUserRef.set({
            UserUid: response.data.data.uid,
            Email: event.data.Email,
            Counter: 12,
            JoinedAt: new Date().toISOString(),
          });
          setNewUid(response.data.data.uid);
          toast("The new user has been created.");
        })
        .catch(function (error) {
          console.log("error", error);
        });
    }
  };

  const onSelectionChanged = () => {
    var selectedRows = gridApi.getSelectedRows();
    setSelectedRows(selectedRows);
  };

  const onPressDelete = async () => {
    gridApi.applyTransaction({ remove: selectedRows });

    let bids = [];
    const snapshot = await database.ref(`BT`).once("value");
    if (!!snapshot.val()) {
      snapshot.forEach((child) => {
        bids.push(child.val());
      });
    }

    selectedRows.forEach((row) => {
      const key = row.UserUid;
      database.ref(`BCT/${key}`).remove();

      const filterBids = bids.filter((bid) => bid.UserUid === key);
      if (filterBids && filterBids.length > 0) {
        filterBids.forEach((bid) => {
          database.ref(`BT/${bid.BidId}`).remove();
        });
      }
    });
    setIsOpen(false);
  };

  const onDeleteUsers = () => {
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const onPressAdd = (e) => {
    e.preventDefault();
    var newItems = [[]];
    var res = gridApi.applyTransaction({
      add: newItems,
      addIndex: 0,
    });
  };

  return (
    <div className="w-full">
      <Navbar />
      <p className="text-center font-bold mb-4">Bonding Companies</p>
      <div className="ag-theme-alpine w-full h-96">
        <AgGridReact
          rowData={users}
          rowHeight={rowHeight}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          rowSelection={"multiple"}
          rowMultiSelectWithClick={true}
          onCellValueChanged={onCellValueChanged}
          onSelectionChanged={onSelectionChanged}
          onGridReady={onGridReady}
          pivotPanelShow={"always"}
          paginationAutoPageSize={true}
          pagination={true}
        >
          <AgGridColumn
            field="UserUid"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
            checkboxSelection={true}
          ></AgGridColumn>
          <AgGridColumn
            field="Email"
            sortable={true}
            filter={true}
            headerName="Bonding Company Email"
          ></AgGridColumn>
          <AgGridColumn
            field="Name"
            sortable={true}
            filter={true}
            headerName="Bonding Company Name"
          ></AgGridColumn>
          <AgGridColumn
            field="DBA"
            sortable={true}
            filter={true}
            headerName="Bonding Company DBA"
          ></AgGridColumn>
          <AgGridColumn
            field="Phone"
            sortable={true}
            filter={true}
            headerName="Bonding Company Phone"
          ></AgGridColumn>
          <AgGridColumn
            field="LicenseNumber"
            sortable={true}
            filter={true}
            headerName="Bonding Company License Number"
          ></AgGridColumn>
          <AgGridColumn
            field="Location"
            sortable={true}
            filter={true}
            headerName="Bonding Company Primary County Served"
          ></AgGridColumn>
          <AgGridColumn
            field="JoinedAt"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
          ></AgGridColumn>
        </AgGridReact>
      </div>
      <div className="flex items-center justify-evenly">
        <button
          className={`w-48 bg-primary text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={onPressAdd}
        >
          Add New User
        </button>
        <button
          className={`w-48 ${
            selectedRows.length === 0 ? "bg-gray-500" : "bg-primary"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={onDeleteUsers}
          disabled={selectedRows.length === 0}
        >
          Delete Selected User
        </button>
        {/* <button
          className={`w-48 ${
            selectedRows.length === 0 ? "bg-gray-500" : "bg-primary"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={onTransferUsers}
          disabled={selectedRows.length === 0}
        >
          Transfer to GC
        </button> */}
      </div>
      <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
        <p className="text-lg">
          Do you really want to delete the selected users?
        </p>
        <div className="flex justify-center">
          <button
            className={`w-24 bg-primary text-white py-1 my-3 mr-3 rounded-full focus:outline-none`}
            onClick={onPressDelete}
          >
            OK
          </button>
          <button
            className={`w-24 bg-primary text-white py-1 my-3 ml-3 rounded-full focus:outline-none`}
            onClick={onCloseModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default SCUsersPage;
