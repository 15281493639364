import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { database } from "../providers/firebase";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const defaultColDef = {
  resizable: true,
  editable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true,
  valueSetter: (params) => {
    params.data[params.colDef.field] = params.newValue;
    return params.newValue !== params.oldValue;
  },
  valueGetter: (params) => params.data[params.colDef.field],
};

const columnTypes = {
  nonEditableColumn: { editable: false },
};

const rowHeight = 42;

const JobsAdminPage = () => {
  const [jobs, setJobs] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      let tempBids = [];
      let updatedBids = [];
      let jobs = [];
      await database.ref("BT").once("value", (snapshot) => {
        if (!!snapshot.val()) {
          snapshot.forEach((child) => {
            tempBids.push(child.val());
          });
        }
      });

      await database.ref("JT").once("value", (snapshot) => {
        if (!!snapshot.val()) {
          snapshot.forEach((child) => {
            jobs.push(child.val());
          });
        }
      });

      let item = {};
        for (item of tempBids) {
          const tempUserId = item.UserUid;
          const tempCreatorId = item.CreatorId;
          const snapshot2 = await database.ref(`FFSRT/${tempCreatorId}`).once("value");
          const ffEmail = snapshot2.val().FFSR?.Email;
          const ffName = snapshot2.val().FFSR?.Name;
          const ffPhone = snapshot2.val().FFSR?.Phone;
          const arresteeName = snapshot2.val().Arrestee?.Name;
          const arresteeFacility = snapshot2.val().Arrestee?.Facility;
          const snapshot1 = await database
            .ref(`BCT/${tempUserId}`)
            .once("value");
         
          const bcEmail = snapshot1.val().Email;
          const requestedDate = jobs.filter(job => job.JobId === item.JobId)[0].RequestedAt;
          const numberOfBCBids = tempBids.filter(bid => bid.JobId === item.JobId).length;
          const newItem = {
            ...item,
            ffEmail,
            ffPhone,
            arresteeName,
            arresteeFacility,
            bcEmail,
            ffName,
            numberOfBCBids,
            requestedDate,
          };
          updatedBids.push(newItem);
        }

      setJobs(updatedBids);
    }

    fetchData();
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onCellValueChanged = (event) => {
    console.log("Data after change is", event.data);
    if (event.data.isHidden == "true") event.data.isHidden = true;
    else event.data.isHidden = false;
    const jobRef = database.ref(`JT/${event.data.JobId}`);
    jobRef.update(event.data);
  };

  const onSelectionChanged = () => {
    var selectedRows = gridApi.getSelectedRows();
    setSelectedRows(selectedRows);
  };

  const onPressDelete = () => {
    gridApi.applyTransaction({ remove: selectedRows });

    selectedRows.forEach((row) => {
      const key = row.BidId;
      database.ref(`BT/${key}`).remove();
    });
    setIsOpen(false);
  };

  const onHideJobs = () => {
    selectedRows.forEach((row) => {
      const rowNode = gridApi.getRowNode(row.JobId);
      rowNode.setData({ ...rowNode.data, isHidden: true });

      const key = row.JobId;
      const jobRef = database.ref(`JT/${key}`);
      jobRef.update({
        isHidden: true,
      });
    });
  };

  const onDeleteJobs = () => {
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  // const onPressAdd = (e) => {
  //   e.preventDefault();
  //   var newItems = [[]];
  //   var res = gridApi.applyTransaction({
  //     add: newItems,
  //   });
  // }

  return (
    <div className="w-full h-screen">
      <Navbar />
      <p className="text-center font-bold mb-4">Bonding Company Submitting Bids</p>
      <div className="ag-theme-alpine w-full h-96">
        <AgGridReact
          rowData={jobs}
          rowHeight={rowHeight}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          rowSelection={"multiple"}
          rowMultiSelectWithClick={true}
          pivotPanelShow={"always"}
          paginationAutoPageSize={true}
          pagination={true}
          onCellValueChanged={onCellValueChanged}
          onSelectionChanged={onSelectionChanged}
          onGridReady={onGridReady}
          getRowNodeId={(data) => data.JobId}
        >
          <AgGridColumn
            field="JobId"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
            checkboxSelection={true}
          ></AgGridColumn>
          <AgGridColumn
            field="bcEmail"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
            headerName="Bonding Company Submitting Bid"
          ></AgGridColumn>
          <AgGridColumn
            field="ffEmail"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
            headerName="Associated Family/Friend Email"
          ></AgGridColumn>
          <AgGridColumn
            field="ffName"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
            headerName="Associated Family/Friend Name"
          ></AgGridColumn>
          <AgGridColumn
            field="ffPhone"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
            headerName="Associated Family/Friend Phone"
          ></AgGridColumn>
          <AgGridColumn
            field="arresteeName"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
            headerName="Associated Arrestee Name"
          ></AgGridColumn>
          <AgGridColumn
            field="arresteeFacility"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
            headerName="Associated Arrsetee Current Jail/Detention Facility"
          ></AgGridColumn>
          <AgGridColumn
            field="requestedDate"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
            headerName="FF Bid Request Date"
          ></AgGridColumn>
          <AgGridColumn
            field="numberOfBCBids"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
            headerName="BC Bids Submitted"
          ></AgGridColumn>
        </AgGridReact>
      </div>
      <div className="flex items-center justify-evenly">
        {/* <button
          className={`w-48 ${
            selectedRows.length === 0 ? "bg-gray-500" : "bg-primary"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={onHideJobs}
          disabled={selectedRows.length === 0}
        >
          Hide Selected Jobs
        </button> */}
        <button
          className={`w-48 ${
            selectedRows.length === 0 ? "bg-gray-500" : "bg-primary"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={onDeleteJobs}
          disabled={selectedRows.length === 0}
        >
          Delete Selected Bids
        </button>
      </div>
      <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
        <p className="text-lg">
          Do you really want to delete the selected bids?
        </p>
        <div className="flex justify-center">
          <button
            className={`w-24 bg-primary text-white py-1 my-3 mr-3 rounded-full focus:outline-none`}
            onClick={onPressDelete}
          >
            OK
          </button>
          <button
            className={`w-24 bg-primary text-white py-1 my-3 ml-3 rounded-full focus:outline-none`}
            onClick={onCloseModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default JobsAdminPage;
